import { app } from '@/application'
import 'bootstrap/js/src/alert'
import template from 'layouts/application/_notices.hbs'

const buildNotice = ({ removeAfter, suppressionCookie, ...options }) => {
  const $notice = $(template(options))

  if (removeAfter) setTimeout(() => $notice.fadeOut(), removeAfter)

  if (suppressionCookie) {
    // Suppress future notices if the close button is clicked.
    //
    $('.btn-close', $notice).on('click', () => {
      app.cookieSet(suppressionCookie, true, { expiresIn: options.dismissible })
    })
  }

  return $notice
}

/**
 * If `dismissible` is truthy the notice will be removable by clicking a close
 * icon.
 *
 * If an `id` is specified and `dismissible` is a number, dismissing the notice
 * will cause all future notifiation with the same `id` to be suppressed for
 * that many milliseconds. This is achieved through the use of cookies that are
 * named using `id`.
 *
 * If `removeAfter` is a number, the notice will be automatically removed after
 * that many milliseconds.
 *
 * Valid types are defined by `$theme-colors`, i.e. they are:
 *
 *   - danger
 *   - dark
 *   - info
 *   - light
 *   - primary
 *   - secondary
 *   - success
 *   - warning
 */
const showNotice = (
  html, type, { dismissible = true, id, removeAfter = 4000 } = {}
) => {
  let suppressionCookie = null

  // Suppress if appropriate.
  //
  if (id && !isNaN(dismissible)) {
    suppressionCookie = `suppress-notice-app-${id}`

    if (app.cookieGet(suppressionCookie)) return
  }

  const $notice = buildNotice(
    { dismissible, html, id, type, removeAfter, suppressionCookie }
  )
  $notice.appendTo($('#notices'))
}

const Mixin = superclass => class extends superclass {
  notifyAlert(html, ...args) {
    return showNotice(html, 'danger', { removeAfter: false, ...args })
  }

  notifyNotice(html, ...args) { return showNotice(html, 'warning', ...args) }

  notifySuccess(html, ...args) { return showNotice(html, 'success', ...args) }
}

export default Mixin
