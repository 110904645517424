import _curryRight from 'lodash/curryRight.js'
import I18n from '@/shared/components/i18n'
import Shepherd from 'shepherd.js'
import 'shepherd.js/dist/css/shepherd'
import './tours.scss'
import apiClient from '@/apiClient'
require.context('../../../../images/tours', true)

const tours = []

const defaultTourOptions = {
  useModalOverlay: true,
  defaultStepOptions: {
    cancelIcon: { enabled: true },
    modalOverlayOpeningPadding: 5,
    modalOverlayOpeningRadius: 5,
    popperOptions: {
      modifiers: [{ name: 'offset', options: { offset: [0, 18] } }]
    }
  },
  scrollTo: true
}

const start = () => {
  const applicableTours = getApplicableTours()

  if (!applicableTours.length) return

  apiClient.get('tour_user')
    .then(({ data }) => {
      const completedTours = data.map(tour => tour.name) ?? []

      const uncompletedTours = applicableTours.filter(
        tour => !completedTours.includes(tour)
      )

      buildTours(uncompletedTours)
      startNextTour()
    })
}

const startNextTour = () => {
  if (tours.length > 0)
    tours.pop().start()
}

const getApplicableTours = () =>
  $('#tours .tour').map(
    (_index, element) => $(element).attr('data-tour-id')
  ).toArray()

const buildStep = (tour, id, step, buttons) => {
  let attachTo = null
  const target = $(step).attr('data-tour-target')

  if (target && $(target).is(':visible'))
    attachTo = { element: target, on: $(step).attr('data-tour-on') }

  tour.addStep({
    arrow: true,
    attachTo,
    buttons: buttons(id, tour),
    id,
    text: step,
    title: $(step).attr('data-tour-title') ?? tour.options.title
  })
}

const buildTour = name => {
  const tourElement = document.querySelector(`[data-tour-id="${name}"].tour`)
  const steps = tourElement.querySelectorAll('[data-tour-step]')

  const tour = new Shepherd.Tour({
    ...defaultTourOptions,
    tourName: name,
    title: tourElement.querySelector('[data-tour-title]').innerHTML
  })

  // Built steps, use different buttons for first and last steps.
  steps.forEach((step, index) => {
    buildStep(tour, index, step, _curryRight(buttons)(steps.length))
  })

  addCompletionListeners(tour, tourElement, name)

  tours.push(tour)
}

const addCompletionListeners = (tour, element, name) => {
  ['cancel', 'complete'].forEach(event => {
    tour.on(event, () => {
      apiClient.create('tour_user', { name })

      startNextTour()
    })
  })

  // Mark the tour as done by clicking a link that has `data-tour-complete`
  // AND if the tour only has ONE step.
  if (tour.steps.length === 1) {
    element.find('a[data-tour-complete]').each((_index, link) => {
      $(link).on('click', () => { apiClient.create('tour_user', { name }) })
    })
  }
}

const buildTours = tours => {
  for (const tour of tours)
    buildTour(tour)
}

const buttons = (index, tour, length) => {
  const back = buildButton(tour.back, 'back', 'Back')
  const done = buildButton(tour.complete, 'done', 'Done')
  const next = buildButton(tour.next, 'next', 'Next')

  switch (Number(index)) {
    case 0:
      return length === 1 ? [done] : [next]
    case length - 1:
      return [back, done]
    default:
      return [back, next]
  }
}

const buildButton = (action, tKey, tDefault) => ({
  text: I18n.t(tKey, { defaultValue: tDefault, scope: 'js.tours.button' }),
  action: action
})

export { start, startNextTour }
