import 'jquery-datetimepicker/build/jquery.datetimepicker.min'
import 'jquery-datetimepicker/build/jquery.datetimepicker.full.js'

/**
 * An instance of DateTimePicker, duly initiliased with desired defaults, can
 * be used to watch one or more containers for elements to be treated as XDSoft
 * DateTimePickers.
 *
 * Create an instance and start watching the DOM:
 *
 *   let dtp = new DateTimePicker()
 *   dtp.start('form', { formatDate: 'y/m/d' })
 *   dtp.start('#section', { allowBlank: false, selector: 'input' })
 *
 * Elements within the specified container that match the specificed selector
 * (default: 'input.date-time-picker') will be lazily initialised upon `focus`.
 * This includes dynamically added elements.
 *
 * Options read from the `data-*` attributes of the element take precedence over
 * those supplied as arguments to `start`, which in turn take precedence over
 * those specified when instantiating the class.
 *
 * Unfortunately, we need to include the timezone offset in the format
 * otherwise it will be lost, hence 'Y-m-d H:i O'.
 */
export class DateTimePicker {
  constructor(...args) {
    this._defaults = Object.assign({
      allowBlank: true,
      defaultSelect: false,
      format: 'Y-m-d H:i O',
      formatDate: 'y-m-d',
      validateOnBlur: false
    }, ...args)
  }

  start(container, { selector = 'input.date-time-picker', ...args } = {}) {
    const defaults = Object.assign({}, this._defaults, args)

    $(container).on('focus', selector, event => {
      const $element = $(event.target)
      if (!$element.data('xdsoft_datetimepicker')) {
        const options = $element.data('datepicker-options')
        $element.datetimepicker(Object.assign({}, defaults, options))
        $element.datetimepicker('show')
      }
    })
  }
}
