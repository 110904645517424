import { Archive as SearchArchiveView } from '@components/search/views/archive'
import initializeSearchForm from '@/app/initializers/searchForm'

export default () => {
  if ($('body.archives.show').length === 0) return

  const $form = $('form#primary-search')
  const searchViewBuilder = search => new SearchArchiveView({
    container: '.search-results',
    pager: '.pagination',
    search: search,
    // The Archive can be performing one of a number of types of search, e.g. a
    // `court_cases` search or a `news` search (see `Search::TYPES` in Ruby). We
    // need to identify the search mode and initialise the Javascript
    // accordingly.
    //
    type: $('input#type', $form).val(),
    // We need to decode the URI as Rails will have encoded any Handlebars tags.
    // Although this may decode other encoded portions of the URL, the Archive
    // class is able to handle it.
    //
    urlTemplate: decodeURI($('.search-results').attr('data-url-template'))
  })
  initializeSearchForm($form, searchViewBuilder)
}
