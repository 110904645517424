var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <li>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td class=\"text-end\">\n      "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"listCount") : depth0), depth0))
    + " / "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"registerCount") : depth0), depth0))
    + "\n    </td>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      \n      <span class=\"d-inline-block fa fa-fw managed-search-toggle-container\"\n        data-name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "\"\n        data-original-query=\"id:"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"trackingId") : depth0), depth0))
    + "\"\n        data-query=\"id:"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"trackingId") : depth0), depth0))
    + "\"\n        data-type=\"court_cases\"></span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <a\n        href=\"#\"\n        class=\"d-inline-block ms-3 tippy\"\n        data-tippy-maxWidth=\"none\"\n        data-tooltip=\"<pre class='explanation'>"
    + container.escapeExpression(__default(require("../../javascript/src/shared/handlebars/json.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"_hit") : depth0),null,2,{"name":"json","hash":{},"data":data,"loc":{"start":{"line":45,"column":47},"end":{"line":45,"column":67}}}))
    + "</pre>\"\n        data-tippy-trigger=\"click\"\n        rel=\"nofollow\"\n      >\n        <i class=\"fa-fw fa-question fas\"></i>\n      </a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n\n<tr>\n  <td>\n    <a href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"path") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "</a>\n  </td>\n  <td>\n    <ul class=\"list-unstyled mb-0\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"venues") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":6},"end":{"line":11,"column":15}}})) != null ? stack1 : "")
    + "    </ul>\n  </td>\n  <td>\n    "
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"summary") : depth0), depth0)) != null ? stack1 : "")
    + "\n  </td>\n  <td>\n"
    + ((stack1 = container.invokePartial(require("../layouts/application/_tags.hbs.erb"),depth0,{"name":"../layouts/application/_tags","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"listCount") : depth0),{"name":"if","hash":{"includeZero":true},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":2},"end":{"line":24,"column":9}}})) != null ? stack1 : "")
    + "  <td>\n    \n    "
    + alias2(__default(require("../../javascript/src/shared/handlebars/ll.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"lastListedOn") : depth0),"DD MMM 'YY",{"name":"ll","hash":{},"data":data,"loc":{"start":{"line":27,"column":4},"end":{"line":27,"column":36}}}))
    + "\n  </td>\n  <td>\n    \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,__default(require("../../javascript/src/shared/handlebars/permission.js")).call(alias3,"core.tracking","creatable",{"name":"permission","hash":{},"data":data,"loc":{"start":{"line":31,"column":10},"end":{"line":31,"column":50}}}),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":4},"end":{"line":38,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"_hit") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":4},"end":{"line":51,"column":11}}})) != null ? stack1 : "")
    + "  </td>\n</tr>\n";
},"usePartial":true,"useData":true});