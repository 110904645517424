import { app } from '@/application'

const submitHandler = event => {
  $('body').addClass('wait')
  event.preventDefault()

  const form = event.target

  app.currentLocation()
    .then(latLon => {
      $('#nogeo', form).remove()
      $('input.ctx-ll', form).val(latLon)
    })
    .finally(() => {
      $('body').removeClass('wait')
      form.submit()
    })
}

const revealElements = query => {
  document.querySelectorAll(query).forEach(element =>
    element.classList.remove('d-none')
  )
}

const handleGeolocationState = (state, $form) => {
  if (state === 'denied')
    revealElements('.geo-denied')
  else if (state === 'granted') {
    revealElements('.geo-granted')
    $form.submit()
  } else
    revealElements('.geo-prompt')
}

export default () => {
  if ($('body.pages-geolocation').length === 0) return

  const $form = $('form#geolocated-form')

  $form.on('submit', submitHandler)

  if (navigator.permissions) {
    navigator.permissions.query({ name: 'geolocation' })
      .then(result => handleGeolocationState(result.state, $form))
  } else
    revealElements('.geo-prompt')
}
