import _range from 'lodash/range.js'
import paginationTemplate from 'kaminari/_paginator.hbs'

const WINDOWS = {
  LEFT: 2,
  INNER: 2,
  RIGHT: 0
}

const PAGE_SIZE = new URLSearchParams(window.location.search).get('per') || 25

const urlBuilder = base => {
  if (!base) return _ => null

  base = new URL(base)

  return page => {
    const query = base.searchParams
    query.set('page', page)
    query.set('per', PAGE_SIZE)
    base.search = query.toString()
    return base.toString()
  }
}

const linkMetaBuilder = (urlFromPage, currentPage) => (page, type = 'page') => {
  let rel
  if (page === currentPage - 1) rel = 'prev'
  else if (page === currentPage + 1) rel = 'next'

  const isCurrentPage = page === currentPage
  const pageData = JSON.stringify({ number: page, size: PAGE_SIZE })

  return { page, pageData, type, rel, url: urlFromPage(page), isCurrentPage }
}

const buildPageList = (currentPage, pageCount) => [
  ...new Set([
    ..._range(1, Math.min(WINDOWS.LEFT, pageCount) + 1),
    ..._range(
      Math.max(1, currentPage - WINDOWS.INNER),
      Math.min(currentPage + WINDOWS.INNER, pageCount) + 1
    ),
    ..._range(pageCount - WINDOWS.RIGHT + 1, pageCount + 1)
  ])
].sort((a, b) => a - b)

// Take the meta data from a paginated API request and return an object suitable
// for use by the Kaminari paginator Handlebars templates. Behaviour should
// mimic Kaminari in respect of inner windows, outer windows, etc.
//
const buildPaginationMeta = ({ page, pages, url }) => {
  const buildMeta = linkMetaBuilder(urlBuilder(url), page)
  let pager = []
  let previousPageNumber = 0

  if (page > 1) pager = [buildMeta(1, 'first'), buildMeta(page - 1, 'prev')]

  buildPageList(page, pages).forEach(pageNumber => {
    if (pageNumber !== previousPageNumber + 1) pager.push({ type: 'gap' })

    pager.push(buildMeta(pageNumber))
    previousPageNumber = pageNumber
  })

  if (previousPageNumber < pages) pager.push({ type: 'gap' })

  if (page < pages)
    pager = [...pager, buildMeta(page + 1, 'next'), buildMeta(pages, 'last')]

  return { pager }
}

export default {
  decorate: buildPaginationMeta,
  templates: { pagination: paginationTemplate }
}
