import I18n from '@/shared/components/i18n'
import archiveTemplate from 'archives/_result.hbs'
import newsFeedTemplate from 'news_feeds/_result.hbs'

// Expected to match the value used in the Archive result partial.
//
const SUMMARY_LENGTH = 1

// eslint-disable-next-line max-lines-per-function
const decorate = listing => {
  const { actor, caseNumber, courtCase, id, listedAt, tags, urn } = listing

  return {
    ...listing,
    actor_name: actor.name,
    lastListedOn: listedAt,
    summary: parseSummary(listing),
    className: `uk-magistrates-${listing.sessionType}`,
    courtShort: formatCourtName(listing),
    path: `/uk/magistrates/listings/${id}`,
    tags: tags.sort((a, b) => a.label.localeCompare(b.label)),
    title: parseTitle(actor.name, (caseNumber || urn)),
    trackingId: courtCase.id,
    venues: formatVenues(listing)
  }
}

const formatCourtName = ({ courtName }) =>
  courtName
    .replace(/magistrate'?s'? courts?/i, 'MC')
    .replace(/youth courts?/i, 'YC')

const formatVenues = listing => {
  if (listing.lja) {
    return [
      I18n.t('venue', {
        court_name: listing.courtName,
        defaultValue: '%{lja} sitting at %{court_name}',
        lja: listing.lja,
        scope: 'js.uk/magistrates/listing'
      })
    ]
  } else
    return [listing.courtName]
}

const parseSummary = ({ moved, matters }) => {
  if (moved) {
    return I18n.t('moved',
      { defaultValue: 'Moved', scope: 'js.uk/magistrates/listing' })
  } else if (matters?.length)
    return mattersSummary(matters)
}

const parseTitle = (actorName, reference) =>
  I18n.t('title', {
    defaultValue: actorName,
    name: actorName.toUpperCase(),
    reference: reference,
    scope: 'js.uk/magistrates/listing'
  })

const mattersSummary = matters => {
  const summaries = [
    ...new Set($.map(matters, matter => matter.summary))
  ].sort()

  // Note that codes are not available in the Common Platform
  // Registers and therefore a summary will not be available.
  //
  if (!summaries.some(s => s)) return

  return I18n.t('summary', {
    count: Math.max(0, summaries.length - SUMMARY_LENGTH),
    defaultValue: '%{summary}',
    scope: 'js.uk/magistrates/listing',
    summary: summaries.slice(0, SUMMARY_LENGTH).join(', ')
  })
}

export default {
  decorate,
  templates: {
    archive: archiveTemplate,
    news: newsFeedTemplate
  }
}
