import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(localizedFormat)

// Be thoughtful when displaying times. By default they will be displayed in the
// user's current timezone as detected by the browser. The backend on the
// otherhand is using the user's explicitly stated preference. These could be
// different. Also important is that it may not be the right thing to do to
// translate listing times to anything other than UK time.
//

export default (datetime, format) => {
  if (typeof datetime !== 'undefined' && datetime !== null)
    return dayjs(datetime).format(format)
  else
    return '\u2014'
}
