import _partialRight from 'lodash/partialRight.js'

/**
 * Abstract class for observer based initialisers.
 *
 * For example consider the case where we want to turn all elements, including
 * those added dynamically, which macth a certain selector into Select2
 * instances. We create a subclass and define a callback function to apply to
 * each newly added matching node:
 *
 *   class Foo extends Base {
 *     constructor() { super({ id: 'Select2Observer' }) }
 *     callback(node, ...args) { $(node).select2() }
 *   }
 *
 * We then instantiate and `start` the observer, telling it which container to
 * observe and what `selector` it should watch out for:
 *
 *   new Foo.start('body', { selector: '.foo' })
 *
 * Now any time a new element is added to the body, both it and its descendants
 * will be compared to the selector `.foo`, and any matching elements will be
 * passed to `callback`, which will convert them into Select2 widgets.
 *
 * When an observer is started it will look for any matching elements in the
 * container and initialise them using the callback. To prevent this pass
 * `skipInitialize: true` to `start`.
 *
 * Note each such class should define a unique `id` for itself. This is used to
 * unbind the observer from DOM elements without interfering with other
 * observers. Each time an observer is started, it will stop any other observers
 * of the same class that are bound to the specified container.
 *
 * Note that this is probably not the best way to achieve the effect so if
 * alternative means exist (e.g. lazy initialisation in response to a user
 * interaction), they should be preferred. E.g. see date_time_picker.js in this
 * directory.
 */
export class Base {
  constructor({ id }) {
    if (new.target === Base)
      throw new TypeError('Cannot instantiate abstract class directly')

    this._id = id
  }

  callback(...args) {
    throw new Error('Not implemented error')
  }

  start(container, { selector, skipInitialize = false, ...args }) {
    const $container = $(container)
    const fn = $node => {
      if ($node.is(selector)) this.callback($node, args)
      $(selector, $node).each((_, node) => { this.callback(node, args) })
    }
    const callback = _partialRight(this._mutationCallback, fn)
    const observer = new MutationObserver(callback)

    if (!skipInitialize)
      $(selector, $container).each((_, node) => { this.callback(node, args) })

    this.stop(container)
    const observerOpts = { attributes: false, childList: true, subtree: true }
    observer.observe($container.get(0), observerOpts)
    $container.data(this._id, observer)
  }

  stop(container) {
    const $container = $(container)
    const observer = $container.data(this._id)

    if (observer) {
      observer.disconnect()
      $container.removeData(this._id)
    }
  }

  _mutationCallback(mutationsList, observer, fn) {
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList')
        for (const node of mutation.addedNodes) fn($(node))
    }
  }
}
