import _defer from 'lodash/defer.js'
import { adapter } from '@components/search/adapters'
import apiClient from '@/apiClient'

export class Search {
  static get STATES() {
    return {
      READY: 'ready',
      LOADING: 'loading',
      DISABLED: 'disabled'
    }
  }

  constructor({ ctx, explain, query } = {}) {
    this._ctx = ctx
    this._explain = explain
    this._meta = null
    this._observers = {}
    this._query = query
    this._results = []
    this._state = this.constructor.STATES.READY
  }

  get meta() {
    return this._meta
  }

  #onSearch = (search, type) => ({ data, meta }) => {
    search._results = data.map(result => search._decorate(result, type))
    search._meta = meta
    search.notify('data:change')
  }

  perform({ page, type, ...params }) {
    if (this.state !== this.constructor.STATES.READY) return

    this.state = this.constructor.STATES.LOADING

    const include = type === 'court_cases'
      ? 'lastListing,listings.actor,matters,tags'
      : 'actor,matters,court_case,tags'

    if (typeof page === 'number') page = { number: page }
    params = { ctx: this.ctx, query: this.query, page, type, ...params }

    if (this._explain) params._explain = 1

    return apiClient.get('search', null, { include, params, path: 'search' })
      .then(this.#onSearch(this, type))
      .catch(this._error.bind(this))
      .finally(() => { this.state = this.constructor.STATES.READY })
  }

  get ctx() {
    return this._ctx
  }

  get query() {
    return this._query
  }

  set query(query) {
    if (this._query !== query) {
      this._query = query
      this.notify('query:change', query)
    }
  }

  get results() {
    return this._results
  }

  get state() {
    return this._state
  }

  set state(state) {
    this._state = state
    this.notify(`state:change:${state}`)
  }

  notify(eventType, ...args) {
    const event = { target: this, type: eventType }
    $.map(this._observers[eventType], fn => _defer(fn, event, ...args))
  }

  subscribe(event, fn) {
    (this._observers[event] ||= []).push(fn)
  }

  _decorate(result, type = 'news') {
    return adapter(type).decorate(result)
  }

  _error(e) {
    this.notify('error', Object.values(e))
  }
}
