import KaminariAdapter from '@components/search/adapters/kaminari'
import UKMagistratesCourtCaseAdapter
  from '@components/search/adapters/uk/magistrates/court_case'
import UKMagistratesListingAdapter
  from '@components/search/adapters/uk/magistrates/listing'

const adapter = type => {
  if (type === 'court_cases')
    return UKMagistratesCourtCaseAdapter
  else if (type === 'news' || type === 'listings')
    return UKMagistratesListingAdapter

  return null
}

export {
  adapter,
  KaminariAdapter,
  UKMagistratesCourtCaseAdapter,
  UKMagistratesListingAdapter
}
