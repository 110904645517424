import _compose from 'lodash/fp/compose.js'
import _mapValues from 'lodash/mapValues.js'
import CookiesMixin from '@/shared/components/mixins/cookies_mixin'
import NoticesMixin from '@/shared/components/mixins/notices_mixin'
import apiClient from '@/apiClient'

const Base = _compose(CookiesMixin, NoticesMixin)(class {})
const GEOLOCATION_OPTIONS = {
  enableHighAccuracy: false,
  maximumAge: 3_600_000 // 1 hour.
}

export class Application extends Base {
  constructor({ client, environment, urls, user }) {
    super()

    this._client = client
    this._urls = _mapValues(urls, url => url.replace(/\/*$/, ''))
    this._user = user
    this._cache = {}

    this.environment = environment
    this.logger = console
  }

  get currentCollectionId() { return this._user.current_collection_id }
  get currentOrganization() { return this._cache.organization }
  get currentUser() { return this._cache.user }

  get isDevelopment() { return this.environment === 'development' }
  get isProduction() { return this.environment === 'production' }
  get isStaging() { return this.environment === 'staging' }

  async fetchCurrentUser(...args) {
    const { data } = await apiClient.get('user', window.USER.id, {
      include: 'organization,managed_searches.collection,permissions',
      ...args
    })

    this._cache.user = data
    this._cache.organization = data.organization

    return data
  }

  // Return a Promise which when successfully resolved will yield the user's
  // current location as determined by the Geolocation API.
  //
  // If a cookie named `ll` exists, Geolocation will be skipped and its value
  // will be returned instead. The cookie is assumed to be signed by Rails.
  //
  currentLocation() {
    return new Promise((resolve, reject) => {
      const latLon = this.cookieGet('ll', { decoder: 'rails' })
      if (latLon) return resolve(latLon)

      if (!navigator.geolocation)
        return reject(new Error('Could not access geolocation!'))

      navigator.geolocation.getCurrentPosition(position => {
        const coordinates = position.coords
        const latLon = `${coordinates.latitude},${coordinates.longitude}`
        resolve(latLon)
      }, reject, GEOLOCATION_OPTIONS)
    })
  }

  /**
   * Delete any access codes or tokens, and clear any cached data. Note, does
   * not revoke any tokens or sign the user out of the web application.
   */
  reset() {
    this._cache = {}
    return Promise.resolve()
  }
}

export const app = new Application({
  client: window.API_CLIENT,
  environment: window.ENV,
  urls: window.ROUTES,
  user: window.USER
})
