import { app } from '@/application'

const oneYear = 31_536_000_000

let collapsedCollections = new Set()

const onCollapse = event => {
  collapsedCollections.add(event.target.id)

  app.cookieSet(
    'collapsed_collections',
    JSON.stringify(Array.from(collapsedCollections)),
    { expiresIn: oneYear }
  )
}

const onExpand = event => {
  collapsedCollections.delete(event.target.id)

  app.cookieSet(
    'collapsed_collections',
    JSON.stringify(Array.from(collapsedCollections)),
    { expiresIn: oneYear }
  )
}

export default () => {
  const $container = $('#collections')

  collapsedCollections = app.cookieGet('collapsed_collections')
  collapsedCollections = new Set(
    collapsedCollections ? JSON.parse(collapsedCollections) : []
  )

  $container.on('hide.bs.collapse', onCollapse)
  $container.on('show.bs.collapse', onExpand)
}
