import I18n from '@/shared/components/i18n'
import archiveTemplate from 'archives/_result.hbs'

// Expected to match the value used in the Archive result partial.
//
const SUMMARY_LENGTH = 3

const decorate = courtCase => {
  const { caseNumber, lastListing, listings, matters, tags, urn } = courtCase

  return {
    ...courtCase,
    actor_name: lastListing.actor.name,
    summary: matters && parseSummary({ matters }),
    lastListedOn: lastListing.date,
    path: `/uk/magistrates/cases/${courtCase.id}`,
    tags: tags.sort((a, b) => a.label.localeCompare(b.label)),
    title: parseTitle(listings, lastListing.actor.name, (caseNumber || urn)),
    trackingId: courtCase.id,
    venues: [...new Set(listings.map(l => l.courtName))].sort()
  }
}

const extractActorNames = listings =>
  $.map(listings, listing =>
    listing.actor.name.replace(/[^\w]/g, '').toLowerCase()
  )

const parseSummary = ({ matters }) => {
  const summaries = [
    ...new Set($.map(matters, matter => matter.summary))
  ].sort()

  return I18n.t('summary', {
    count: Math.max(0, summaries.length - SUMMARY_LENGTH),
    defaultValue: '%{summary}',
    scope: 'js.uk/magistrates/court_case',
    summary: summaries.slice(0, SUMMARY_LENGTH).join(', ')
  })
}

const parseTitle = (listings, actorName, reference) => {
  const actorCount = [
    ...new Set(extractActorNames(listings))
  ].length

  return I18n.t('title', {
    count: (actorCount - 1), // additional actors count
    defaultValue: actorName,
    name: actorName.toUpperCase(),
    reference: reference,
    scope: 'js.uk/magistrates/court_case'
  })
}

export default {
  decorate,
  templates: { archive: archiveTemplate }
}
