import { OAuth2AuthCodePKCE } from '@bity/oauth2-auth-code-pkce'

const LOCATION_STORE_KEY = 'oauth-location-before-redirect'
const { redirectUrl, scopes, id: clientId } = window.API_CLIENT
const { authorize: authorizationUrl, token: tokenUrl } = window.ROUTES

// These handlers prevent a bug with concurrent token refresh attempts:
// https://github.com/BitySA/oauth2-auth-code-pkce/issues/29
let expiryPromise
let invalidGrantPromise

const onAccessTokenExpiry = async refreshAccessToken => {
  if (!expiryPromise)
    expiryPromise = refreshAccessToken()

  const result = await expiryPromise
  expiryPromise = undefined
  return result
}

const onInvalidGrant = async refreshAuthCodeOrRefreshToken => {
  if (!invalidGrantPromise)
    invalidGrantPromise = refreshAuthCodeOrRefreshToken()

  await invalidGrantPromise
  invalidGrantPromise = undefined
}

export const oauth = new OAuth2AuthCodePKCE({
  authorizationUrl,
  tokenUrl,
  clientId,
  scopes,
  redirectUrl,
  onAccessTokenExpiry,
  onInvalidGrant
})

const setReturnLocation = () => {
  sessionStorage.setItem(LOCATION_STORE_KEY, window.location.href)
}

const returnToStoredLocation = () => {
  const returnLocation = sessionStorage.getItem(LOCATION_STORE_KEY) ?? '/'
  window.location = returnLocation
}

const login = () => {
  setReturnLocation()
  oauth.fetchAuthorizationCode()
}

export const authorize = async() => {
  const hasCode = await oauth.isReturningFromAuthServer()
  if (!hasCode) {
    if (!oauth.isAuthorized()) login()
    return oauth.isAuthorized()
  }

  await oauth.getAccessToken()
  returnToStoredLocation()
  return oauth.isAuthorized()
}

// If there's an invalid_client error try to refresh the token.
// This is needed to grab a new token when a token has been revoked, expired, or
// deleted server-side.
//
export const oauthMiddleware = (url, options, innerFetch) =>
  innerFetch(url, options)
    .then(async res => {
      if (res.status !== 401) return res

      const { error, errors } = await res.json()
      const invalid = error === 'invalid_client' ||
        errors?.some(e => e.title === 'Invalid JWT')
      if (!invalid) return res

      oauth.exchangeRefreshTokenForAccessToken()
    })
