import initializeAdvancedSearch from '@/app/initializers/advancedSearch'
import initializeArchive from '@/app/initializers/archive'
import initializeCollectionCollapsibles
  from '@/app/initializers/collectionCollapsibles'
import initializeCollectionSearchables
  from '@/app/initializers/collectionSearchables'
import initializeCollectionSortables
  from '@/app/initializers/collectionSortables'
import initializeDocuments from '@/app/initializers/documents'
import initializeGeolocation from '@/app/initializers/geolocation'
import initializeNewsFeed from '@/app/initializers/newsFeed'
import initializeManagedSearch from '@/app/initializers/managedSearch'
import initializeNotifications from '@/app/initializers/notifications'

export default [
  initializeAdvancedSearch,
  initializeArchive,
  initializeCollectionCollapsibles,
  initializeCollectionSearchables,
  initializeCollectionSortables,
  initializeDocuments,
  initializeGeolocation,
  initializeNewsFeed,
  initializeManagedSearch,
  initializeNotifications
]
