import I18n from '@/shared/components/i18n'
import { app } from '@/application'

export class Base {
  constructor({ container, pager = null, search, type, wrapper = 'body' }) {
    this._container = $(container)
    this._pager = pager && $(pager)
    this._search = search
    this._type = type
    this._wrapper = $(wrapper)
  }

  // TODO: Disable forms
  //
  disable() {
    this._pager.addClass('disabled')
    this._wrapper.addClass('search-disabled')
  }

  loading() {
    this._wrapper.addClass('search-loading')
  }

  popState(_event) {
  }

  pushState(_data, _title, _url) {
  }

  ready() {
    this._pager.removeClass('disabled')
    this._wrapper.removeClass('search-disabled search-loading')
  }

  initializePager() {
    if (!this._pager) return

    window.addEventListener('popstate', this.popState.bind(this))

    this._pager.on('click', 'a', event => {
      if (!this._pager.is('.disabled')) {
        const $target = $(event.currentTarget)

        this._search
          .perform({ type: this._type, ...$target.data() })
          .then(() => this.pushState($target.data(), '', $target.attr('href')))
      }
      return false
    })
  }

  start() {
    this._search.subscribe('data:change', this.updateResults.bind(this))
    this._search.subscribe('error', this._error.bind(this))
    this._search.subscribe('query:change', this.updateForms.bind(this))
    this._search.subscribe('state:change:disabled', this.disable.bind(this))
    this._search.subscribe('state:change:loading', this.loading.bind(this))
    this._search.subscribe('state:change:ready', this.ready.bind(this))
    return this.initializePager()
  }

  updateForms(_event) {
  }

  updateResults(_event) {
    this._container.append($.map(this._search.results, this._template))
  }

  _error(_event, errors) {
    errors.forEach(e => {
      const message = I18n.t(e.code || 'default', {
        defaultValue: e.detail || e.title,
        scope: 'js.api.errors',
        ...e
      })
      app.notifyAlert(message)
    })
  }

  _template(result) {
    throw new Error('Not implemented error')
  }
}
