import { Deserializer, Serializer } from 'jsonapi-serializer'
import { camelCase, mapKeys } from 'lodash'
import * as serializerModules0 from './serializers/managedSearch.js'; import * as serializerModules1 from './serializers/tourUser.js'; import * as serializerModules2 from './serializers/user.js'; var serializerModules = [serializerModules0, serializerModules1, serializerModules2]

const DEFAULT_OPTIONS = {
  keyForAttribute: 'snake_case'
}

const serializers = new Map(serializerModules.map(
  ({ default: { collectionName, opts } }) => ([
    collectionName,
    new Serializer(collectionName, { ...DEFAULT_OPTIONS, ...opts })
  ])
))

const DESERIALIZER = new Deserializer({ keyForAttribute: 'camelCase' })

export const jsonApiMiddleware = (url, { body, type, ...opts }, innerFetch) =>
  innerFetch(url, {
    ...opts,
    body: body && JSON.stringify(serializers.get(type).serialize(body))
  })
    .then(async res => {
      if (res.status === 204) return

      let { meta, links, ...jsonAPI } = await res.json()
      if (!res.ok) throw jsonAPI.errors

      const data = await DESERIALIZER.deserialize(jsonAPI)
      meta = mapKeys(meta, (_v, key) => camelCase(key))

      return { meta, links, data }
    })
