import { adapter, KaminariAdapter } from '@components/search/adapters'
import { Base } from '@components/search/views/base'
import Handlebars from 'handlebars'

export class Archive extends Base {
  // `urlTemplate` A Handlebars template string. When evaluated with the current
  //               `query` as a variable, it should return the appropriate end
  //               point (absolute URL) for the desired archive search. A
  //               suitable `page` query parameter will be added/overwritten
  //               automatically. E.g.
  //
  //                 * 'https://news.courtsdesk.co.uk/archive?q={{query}}&per=5'
  //                 * 'https://news.courtsdesk.co.uk/archive/{{query}}?per=5'
  //
  constructor({ urlTemplate, ...args }) {
    super({ type: 'court_cases', ...args })
    this._urlTemplate = Handlebars.compile(urlTemplate)
  }

  popState(event) {
    // Although we currently reload the page whenever the search query changes,
    // we future proof ourselves by storing the query in the History state, and
    // updating the underlying Search object whenever we pop. This will ensure
    // that the correct search is always being performed and that any
    // appropriate events are fired.
    //
    if (event.state) this._search.query = event.state.query
    this._search.perform({ type: this._type, ...event.state })
  }

  pushState(data, title, url) {
    history.pushState({ query: this._search.query, ...data }, title, url)
  }

  get url() {
    const { query } = this._search
    // We explicitly encode the query here so that the Handlebars' template
    // does not convert the quotes to the corresponding HTML entities.
    //
    return this._urlTemplate({ query: encodeURI(query) })
  }

  updateResults(event) {
    this._container.empty()
    super.updateResults(event)

    const data = KaminariAdapter.decorate({
      url: this.url,
      ...this._search.meta
    })
    this._pager.html(KaminariAdapter.templates.pagination(data))
  }

  _template(result) {
    return adapter('court_cases').templates.archive(result)
  }
}
