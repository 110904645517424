// See https://github.com/browser-update/browser-update for options.

import browserUpdate from 'browser-update'
import '@/shared/stylesheets/browser_update'

const requiredBrowsers = {
  c: -3,
  e: -4,
  f: -3,
  o: -3,
  s: -1
}

const message = `
        To ensure that Courtsdesk UK and other sites that you visit work as
        intended, and to improve both your speed and security, you should update
        your browser now.
      `

$(() => {
  browserUpdate({
    mobile: false,
    newwindow: true,
    nostatistics: true,
    reminder: 24,
    reminderClosed: 168,
    required: requiredBrowsers,
    test: false,
    text: { msgmore: message }
  })
})
