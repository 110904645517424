import { app } from '@/application'
import '@/shared/components/bootstrap'
import '@/shared/components/browser_update'
import { DateTimePicker } from '@/shared/components/observers/date_time_picker'
import { FormValidator } from '@/shared/components/observers/form_validator'
import { Popover } from '@/shared/components/observers/popover'
import { Select2 } from '@/shared/components/observers/select2'
import { Tippy } from '@/shared/components/observers/tippy'
import { start as startTours } from '@components/tours/tours'
import 'jquery-ui/ui/widgets/droppable'
import 'jquery-ui/ui/widgets/sortable'
import '@/app/stylesheets/app'

import initializers from '@/app/initializers'
import { authorize } from '@/apiClient/oauth'

$(() => {
  new DateTimePicker().start('body')
  new FormValidator().start('body')
  new Popover().start('body', { container: '#main-container' })
  new Select2().start('body')
  new Tippy().start('body', { selector: '.tippy' })
  // Suppress clicks on "#" links.
  //
  $('body').on('click', 'a[href="#"]', e => e.preventDefault())
})

$(() => {
  // We can start the Application/API regardless of whether the user is signed
  // in to the web application, but we'll elect not to as to do so would cause
  // unauthenticated users to be directed to the sign in page, even from
  // unauthenticated pages.
  //
  if (!window.USER.id) return

  authorize().then(isAuthorized => {
    if (isAuthorized) {
      app.fetchCurrentUser()
        .then(() => {
          initializers.forEach(initializer => initializer())
          startTours()
        })
    }
  })
})
