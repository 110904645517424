import { oauth, oauthMiddleware } from '@/apiClient/oauth'
import { jsonApiMiddleware } from '@/apiClient/jsonAPI'
import fetchWrap, { merge } from 'fetch-wrap'
import pluralize from 'pluralize'
import qs from 'qs'

const DEFAULT_HEADERS = {
  Accept: 'application/vnd.api+json',
  'Content-Type': 'application/vnd.api+json'
}

const defaultHeaderMiddleware = (url, options, innerFetch) =>
  innerFetch(url, merge(options, { headers: DEFAULT_HEADERS }))

const oauthFetch = oauth.decorateFetchHTTPClient(fetch)

const urlParamsMiddleware = (url, { params, ...options }, innerFetch) => {
  if (params) url = `${url}?${qs.stringify(params)}`

  return innerFetch(url, options)
}

const apiFetch = fetchWrap(oauthFetch, [
  urlParamsMiddleware,
  defaultHeaderMiddleware,
  jsonApiMiddleware,
  oauthMiddleware
])

const buildURL = (type, { path, id }) => {
  const root = window.ROUTES.api
  path ??= `${pluralize(type)}${id ? `/${id}` : ''}`
  return `${root}${path}`
}

const jsonApiToFetch = (type, { id, path, include, fields, ...options }) =>
  apiFetch(
    buildURL(type, { path, id }),
    {
      type,
      ...options,
      params: { ...options.params, include, fields }
    })

export default {
  fetch: apiFetch,
  create: (type, body, options) =>
    jsonApiToFetch(type, { ...options, body, method: 'POST' }),
  destroy: (type, id, options) =>
    jsonApiToFetch(type, { ...options, id, method: 'DELETE' }),
  get: (type, id, options) => jsonApiToFetch(type, { ...options, id }),
  update: (type, id, body, options) =>
    jsonApiToFetch(type, { ...options, body, id, method: 'PATCH' })
}

