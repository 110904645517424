import { adapter } from '@components/search/adapters'
import { Base } from '@components/search/views/base'

export class NewsFeed extends Base {
  constructor({ ...args }) {
    super({ ...args, type: 'news' })
  }

  updateResults(event) {
    const { meta } = this._search
    const top = $(document).scrollTop()
    super.updateResults(event)
    $(document).scrollTop(top)

    if (meta.isLastPage)
      this._pager.remove()
    else {
      this._pager.find('a').attr('href', '#')
        .data('page', { size: meta.per, number: meta.page + 1 })
    }
  }

  _template(result) {
    return adapter('news').templates.news(result)
  }
}
