import dayjs from 'dayjs'

// Options:
//
//   from  The datetime relative to which to compute the difference, defaults to
//         current time.
//   unit  The unit in which to report differences, e.g. 'hours', 'days', etc.,
//         defaults to 'hours'.
//
export default (datetime, options) => {
  let from = dayjs(options.hash.from) // Defaults to now.
  let to = dayjs(datetime)
  if (from < to) [from, to] = [to, from]

  return from.diff(to, options.hash.unit || 'hours', false)
}
