var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"dateOfBirth") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":18},"end":{"line":37,"column":61}}})) != null ? stack1 : "")
    + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ",\n            "
    + container.escapeExpression(__default(require("../../javascript/src/shared/handlebars/datetime_diff.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"dateOfBirth") : depth0),{"name":"datetime_diff","hash":{"unit":"years"},"data":data,"loc":{"start":{"line":37,"column":12},"end":{"line":37,"column":54}}}));
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        \n        <span\n          class=\"fa fa-fw managed-search-toggle-container\"\n          data-name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "\"\n          data-original-query=\"id:"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"courtCase") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\"\n          data-query=\"id:"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"courtCase") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\"\n          data-type=\"court_cases\"\n        ></span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <a href=\"#\" class=\"d-inline-block ms-3 tippy\"\n          data-tippy-maxWidth=\"none\"\n          data-tooltip=\"<pre class='explanation'>"
    + container.escapeExpression(__default(require("../../javascript/src/shared/handlebars/json.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"_hit") : depth0),null,2,{"name":"json","hash":{},"data":data,"loc":{"start":{"line":72,"column":49},"end":{"line":72,"column":69}}}))
    + "</pre>\"\n          data-tippy-trigger=\"click\"\n          rel=\"nofollow\"\n        >\n          <i class=\"fa-fw fa-question fas\"></i>\n        </a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n\n<div class=\"card mb-3 mx-2 shadow "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"className") : depth0), depth0))
    + "\">\n  <a class=\"d-flex flex-column flex-fill\"\n    href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"path") : depth0), depth0))
    + "\" target=\"_blank\"\n  >\n    <div class=\"bg-transparent border-bottom-0 card-header\">\n      <span class=\"d-block float-end text-muted\">\n        <time class=\"d-block ps-2\" datetime=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"createdAt") : depth0), depth0))
    + "\">\n          <i class=\"d-inline-block fa-clock fa-fw fa-nohover fal pe-1\"></i>\n          "
    + alias2(__default(require("../../javascript/src/shared/handlebars/t.js")).call(alias3,"hr",{"name":"t","hash":{"scope":"js.uk/magistrates/listing","defaultValue":"%{count} hrs","count":__default(require("../../javascript/src/shared/handlebars/datetime_diff.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"createdAt") : depth0),{"name":"datetime_diff","hash":{"unit":"hours"},"data":data,"loc":{"start":{"line":11,"column":25},"end":{"line":11,"column":63}}})},"data":data,"loc":{"start":{"line":11,"column":10},"end":{"line":11,"column":127}}}))
    + "\n        </time>\n      </span>\n\n      <span class=\"d-block text-muted\">\n        <span\n          class=\"tippy\"\n          data-tooltip=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"venues") : depth0), depth0))
    + "\"\n        >\n          "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"courtShort") : depth0), depth0))
    + "\n        </span>\n      </span>\n\n      <span class=\"d-block text-muted\">\n        "
    + ((stack1 = __default(require("../../javascript/src/shared/handlebars/t.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"sessionType") : depth0),{"name":"t","hash":{"scope":"js.uk/magistrates/session.type","defaultValue":"&ndash;"},"data":data,"loc":{"start":{"line":25,"column":8},"end":{"line":25,"column":89}}})) != null ? stack1 : "")
    + "\n      </span>\n    </div>\n\n    <div class=\"card-body d-flex flex-column pt-0\">\n      <h6 class=\"card-text text-uppercase\">\n        "
    + ((stack1 = __default(require("../../javascript/src/shared/handlebars/or_dash.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"summary") : depth0),{"name":"or_dash","hash":{},"data":data,"loc":{"start":{"line":31,"column":8},"end":{"line":31,"column":29}}})) != null ? stack1 : "")
    + "\n      </h6>\n\n      <h3 class=\"card-title mb-4 text-uppercase\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias3,(depth0 != null ? lookupProperty(depth0,"actor") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":8},"end":{"line":38,"column":17}}})) != null ? stack1 : "")
    + "      </h3>\n\n      <time class=\"card-text mt-auto text-muted\" datetime=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"listedAt") : depth0), depth0))
    + "\">\n        "
    + alias2(__default(require("../../javascript/src/shared/handlebars/ll.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"listedAt") : depth0),"LL",{"name":"ll","hash":{},"data":data,"loc":{"start":{"line":42,"column":8},"end":{"line":42,"column":28}}}))
    + "\n      </time>\n    </div>\n  </a>\n\n  <div class=\"bg-white card-footer d-flex text-muted\">\n    <div class=\"badges\">\n"
    + ((stack1 = container.invokePartial(require("../layouts/application/_tags.hbs.erb"),depth0,{"name":"../layouts/application/_tags","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"actions ms-auto text-end\">\n      \n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,__default(require("../../javascript/src/shared/handlebars/permission.js")).call(alias3,"core.tracking","creatable",{"name":"permission","hash":{},"data":data,"loc":{"start":{"line":54,"column":12},"end":{"line":54,"column":52}}}),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":6},"end":{"line":63,"column":13}}})) != null ? stack1 : "")
    + "\n      \n\n      \n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"_hit") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":69,"column":6},"end":{"line":78,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n  </div>\n</div>\n";
},"usePartial":true,"useData":true});