import { app } from '@/application'
import _defer from 'lodash/defer.js'
import _find from 'lodash/find.js'
import apiClient from '@/apiClient'

export class ManagedSearch {
  constructor() {
    this._observers = {}
  }

  get managedSearches() {
    return app.currentUser.managedSearches
  }

  add(managedSearch) {
    return apiClient
      .create('managed_search', managedSearch, { include: 'collection' })
      .then(({ data }) => {
        this.managedSearches.push(data)
        this.notify('data:add', data)
      })
      .catch(this._error.bind(this))
  }

  delete(id) {
    return apiClient.destroy('managed_search', id)
      .then(() => {
        const index = this.managedSearches.findIndex(ms => ms.id === id)
        let managedSearch = { id }
        if (index !== -1)
          managedSearch = this.managedSearches.splice(index, 1)[0]
        this.notify('data:remove', managedSearch)
      })
  }

  find({ collection, id, query, type }) {
    let fn = null
    if (id) fn = ms => ms.id === id
    else {
      fn = ms =>
      // WARNING: We do not consider `ctx` when comparing two ManagedSearches.
      //          This is discussed a little in the Ruby model but be aware that
      //          by the metric defined here two distinct ManagedSearches can be
      //          erroneously conflated.
      //
        ms.collection.id === collection.id &&
          ms.type === type && ms.query === query
    }

    return _find(this.managedSearches, fn)
  }

  notify(eventType, ...args) {
    const event = { target: this, type: eventType }
    $.map(this._observers[eventType], fn => _defer(fn, event, ...args))
  }

  subscribe(event, fn) {
    (this._observers[event] ||= []).push(fn)
  }

  _error(e) {
    this.notify('error', Object.values(e))
  }
}
