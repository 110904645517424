import apiClient from '@/apiClient'

// Unfortunately, ui.sender and event.target differ in the receive and stop
// callbacks so the list must be retrieved differently in both and passed
// explicitly to this function.
//
const updateManagedSearch = (collectionId, element, id, list) => {
  apiClient.update('managed_search', id, { collection: { id: collectionId } })
    .then(() => {
      element.addClass('animate__animated animate__flash')
    })
    .catch(() => {
      list.sortable('cancel')
      element.addClass('animate__animated animate__flash text-danger')
    })

  element.one('animationend', () => {
    element.removeClass('animate__animated animate__flash text-danger')
  })
}

const onSortReceive = ({ target: { dataset: { id: collectionId } } }, ui) => {
  const $element = $(ui.item)
  const $list = $(ui.sender)
  const id = $element.data('id')

  updateManagedSearch(collectionId, $element, id, $list)
}

const onSortStart = $container => (_event, _ui) => {
  $container.addClass('dragging')
}

const onSortStop = $container => (event, ui) => {
  const receiver = ui.item.data('receiver')

  if (receiver) {
    const collectionId = $(receiver).data('id')
    const $header = $(receiver).parents('.collection').siblings('h6')
    const $list = $(event.target)
    const id = $(ui.item).data('id')

    receiver.append(ui.item)
    updateManagedSearch(collectionId, $header, id, $list)

    $(ui.item).data('receiver', null)
  }

  $container.removeClass('dragging')
}

const initializeSorting = $container => {
  $('.sortable', $container).sortable({
    axis: 'y',
    connectWith: '.sortable',
    forcePlaceholderSize: true,
    opacity: 0.8,
    placeholder: 'bg-light',
    receive: onSortReceive,
    start: onSortStart($container),
    stop: onSortStop($container)
  })
}

export default () => {
  if ($('body.collections.index').length === 0)
    return

  const $container = $('#collections')

  $('h6', $container).droppable({
    drop: (event, ui) => {
      // Only allow the item to be dropped on the header if it is collapsed.
      //
      if (!$('.toggler', event.target).hasClass('collapsed'))
        return false
      const receiver = $(event.target).parent().find('.sortable')
      $(ui.draggable).data('receiver', receiver)
    }
  })

  initializeSorting($container)
}
