import { app } from '@/application'
import _defaultsDeep from 'lodash/defaultsDeep'
import { Base as ObserverBase } from '@/shared/components/observers/base'
import I18n from '@/shared/components/i18n'
import template from 'managed_searches/_toggle.hbs'

export class Toggle extends ObserverBase {
  constructor({ container, managedSearch, ...args }) {
    super({ id: 'ManagedSearchView' })
    this._defaults = args
    this._container = $(container)
    this._managedSearch = managedSearch
  }

  callback(element, args) {
    const $element = $(element)

    /**
     * Note that there is some fussing here in order to assign the value of
     * `collection.id`. If one comes in from the DOM we use that (this will
     * happen because the DOM was rendered by ERB and the default collection id
     * was inserted). Otherwise we use the current default collection, obtained
     * from the Application instance, taking care to ensure that blank DOM data
     * attributes don't clobber the default (hence the `_defaultsDeep`).
     */
    const managedSearch = _defaultsDeep(
      $element.data(), args, this._defaults,
      { collection: { id: app.currentCollectionId } }
    )
    managedSearch.id = (this._managedSearch.find(managedSearch) || {}).id

    /**
     * Ensure that each ManagedSearch has a unique identifier, so that if/when
     * there are multiple interface elements representing it they can be kept
     * in sync.
     */
    managedSearch._uid = this._toUID(managedSearch)
    $element.html(template(managedSearch))
  }

  // Observe the DOM for the addition of new placeholder elements into which
  // MangaedSearch toggles should be inserted, listen to the ManagedSearch
  // instance for the addition or removal of ManagedSearch records which might
  // warrant a DOM update, and listen for form submission events that should
  // trigger the creation/deletion of ManagedSearches.
  //
  start({ selector = '.managed-search-toggle-container:empty', ...args } = {}) {
    super.start(this._container, { selector: selector, ...args })
    this._managedSearch.subscribe('data:add', this.toggle.bind(this))
    this._managedSearch.subscribe('data:remove', this.toggle.bind(this))
    this._managedSearch.subscribe('error', this._error.bind(this))
    this._initializeToggleListeners()
  }

  toggle(event, managedSearch) {
    const uid = managedSearch._uid ??= this._toUID(managedSearch)

    const toggles = this._container.get(0)
      .querySelectorAll(`.managed-search-toggle[data-uid="${uid}"]`)

    if (toggles.length === 0) return

    if (event.type === 'data:remove')
      this._onSearchDeleted(toggles)
    else if (event.type === 'data:add')
      this._onSearchSaved(toggles, managedSearch.id)
  }

  _onSearchSaved(toggles, id) {
    toggles.forEach(toggle => {
      toggle.querySelector('form.delete input[name=id]')
        .value = id
      toggle.classList.remove('new')
    })

    app.notifySuccess(I18n.t('created', {
      defaultValue: 'Tracking activated',
      scope: 'js.managed_searches.toggle'
    }), { id: 'news-feed-track-add', dismissible: 1000 * 120 })
  }

  _onSearchDeleted(toggles) {
    toggles.forEach(toggle => {
      toggle.querySelector('form.delete input[name=id]').value = null
      toggle.classList.add('new')
    })

    app.notifyNotice(I18n.t('deleted', {
      defaultValue: 'Tracking deactivated',
      scope: 'js.managed_searches.toggle'
    }), { id: 'news-feed-track-remove', dismissible: 1000 * 120 })
  }

  _initializeToggleListeners() {
    this._container.on('submit', '.managed-search-toggle form', event => {
      event.preventDefault()
      const { currentTarget: form } = event

      if (form.classList.contains('create'))
        this._createManagedSearch(form)
      else if (form.classList.contains('delete')) {
        const id = form.querySelector('input[name=id]').value

        this._managedSearch.delete(id)
      }

      return false
    })
  }

  _createManagedSearch(form) {
    const formData = new FormData(form)
    this._managedSearch.add({
      collection: { id: formData.get('managed_search[collection][id]') },
      ctx: { ll: formData.get('managed_search[ctx][ll]') },
      name: formData.get('managed_search[name]'),
      originalQuery: formData.get('managed_search[original_query]'),
      query: formData.get('managed_search[query]'),
      type: formData.get('managed_search[type]')
    })
  }

  _error(_event, errors) {
    errors.forEach(e => app.notifyAlert(e.description || e.title))
  }

  // WARNING: We do not consider `ctx` when constructing the `uid` of a
  //          ManagedSearch. This is discussed a little in the Ruby model but
  //          be aware that by this definition two distinct ManagedSearches can
  //          be erroneously conflated.
  //
  _toUID(managedSearch) {
    // This value needs to be safe for use as a DOM attribute value. As `query`
    // may contain unsuitable characters we URI encode it first.
    //
    return [
      managedSearch.collection.id,
      managedSearch.type,
      encodeURIComponent(managedSearch.query)
    ].join('/')
  }
}
