import { app } from '@/application'
import { ManagedSearch } from '@components/managed_search/managed_search'
import { Toggle as ManagedSearchView }
  from '@components/managed_search/views/toggle'

const _addLocationUpdateListener = () => {
  if ($('body.managed-searches').is('.edit, .new')) {
    const $form = $('form.managed-search-form')

    $form.on('click', '.update-location', event => {
      event.preventDefault()

      app.currentLocation()
        .then(latLon => $('input.ctx-ll', $form).val(latLon))
    })
  }
}

export default () => {
  _addLocationUpdateListener()

  const managedSearch = new ManagedSearch()

  // The ManagedSearchView will observe the DOM for the addition of
  // ManagedSearch placeholders and initialise them.
  //
  const managedSearchView = new ManagedSearchView({
    container: $('body'),
    managedSearch: managedSearch
  })
  managedSearchView.start()
}
