import { I18n } from 'i18n-js'
import translations from 'translations.json'

const i18n = new I18n()
i18n.store(translations)
i18n.defaultLocale = 'en'
i18n.enableFallback = true
if (window.LOCALE) i18n.locale = window.LOCALE

export default i18n
