export default () => {
  const $container = $('#collections')

  $('#collection_filter').on('input', event => {
    const searchValue = $(event.target).val().toLowerCase()

    $container.children('.collection').each((_index, collection) => {
      const collectionName = $(collection).data('name').toLowerCase()
      $(collection).toggle(collectionName.includes(searchValue))
    })
  })

  $('#collection_filter').on('search', () => {
    $container.children('.collection').each(collection => {
      $(collection).toggle(true)
    })
  })
}
