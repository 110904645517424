import '@/shared/stylesheets/popover.scss'

import { Popover as BSPopover, Tooltip as bsTooltip } from 'bootstrap'
import { Base } from '@/shared/components/observers/base'

const { allowList } = bsTooltip.Default
allowList.dd = []
allowList.dl = []
allowList.dt = []

export class Popover extends Base {
  constructor(...args) {
    super({ id: 'PopoverObserver' })

    this._defaults = Object.assign({}, ...args)
  }

  callback(element, args) {
    const $element = $(element)

    return new BSPopover(
      $element,
      Object.assign({}, this._defaults, args, $element.data('popover-options'))
    )
  }

  start(container, { selector = '[data-bs-toggle="popover"]', ...args } = {}) {
    super.start(container, { selector: selector, ...args })
  }
}
