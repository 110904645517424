import { Base } from '@/shared/components/observers/base'

export class FormValidator extends Base {
  constructor(...args) {
    super({ id: 'FormValidator' })
  }

  callback(element, args) {
    const $element = $(element)

    $element.on('submit', event => {
      if (element.checkValidity() === false) {
        // If the form has previously been submitted and rejected, error
        // messages generated server-side may be visibile. Assuming the user
        // can modify their input before attempting to submit again and
        // triggering this validation process, those error messages may no
        // longer be accurate. So we swap them out for the default message
        // stored as `data-default`.
        //
        $('.invalid-feedback', $element).each((_, element) => {
          const $element = $(element)
          $element.html($element.data('default'))
        })

        event.preventDefault()
        event.stopPropagation()
      }
      $element.addClass('was-validated')
    })
  }

  start(container, { selector = 'form.needs-validation', ...args } = {}) {
    super.start(container, { selector: selector, ...args })
  }
}
