var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0)) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    return "#";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"next page-item\">\n  <a href=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"url") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":11},"end":{"line":2,"column":47}}})) != null ? stack1 : "")
    + "\" class=\"page-link\" data-page=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"pageData") : depth0), depth0))
    + "\" rel=\"next\" remote=\"remote\">\n    "
    + ((stack1 = __default(require("../../javascript/src/shared/handlebars/t.js")).call(alias1,"js.pagination.next",{"name":"t","hash":{"defaultValue":"Next"},"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":52}}})) != null ? stack1 : "")
    + "\n  </a>\n</li>\n";
},"useData":true});