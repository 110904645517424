const termRegex = /[^\s"]+|"[^"]+"/g

const submitHandler = event => {
  event.preventDefault()

  const { target: form } = event
  const hiddenField = form.querySelector('#query')
  const data = new FormData(form)
  const query = queryFromFields(data)

  hiddenField.value = query

  form.submit()
}

const transformTerms = (expression, transformer) =>
  expression.match(termRegex).map(transformer).join(' ')

const tagWith = (expression, tag) => {
  if (/\s/.test(expression))
    expression = `"${expression}"`

  return tag + expression
}

// The keys here have to match the names of the fields in the form
//
const fieldTransformers = {
  plain: expression => expression,
  any: expression => `(${expression.match(termRegex).join(' || ')})`,
  phrase: expression => `"${expression.replace(/"/g, '')}"`,
  exact: expression => transformTerms(expression, term => `+${term}`),
  exclude: expression => transformTerms(expression, term => `-${term}`),
  listed_on: expression => tagWith(expression, 'listed:'),
  born_on: expression => tagWith(expression, 'dob:'),
  born_from: expression => tagWith(expression, 'dob:>'),
  born_to: expression => tagWith(expression, 'dob:<'),
  'tags[]': expression => {
    if (expression) return tagWith(expression, 'tags:')
  }
}

const queryFromFields = data =>
  Array.from(data.entries()).reduce((query, [field, value]) => {
    if (field === 'commit') return query

    if (value) {
      if (fieldTransformers[field])
        query.push(fieldTransformers[field](value))
      else
        query.push(tagWith(value, `${field}:`))
    }

    return query
  }, []).join(' ')

export default () => {
  const form = document.forms.namedItem('advanced-search')
  if (!form) return

  form.addEventListener('submit', submitHandler)
}
