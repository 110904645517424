import _reduce from 'lodash/reduce.js'

const Mixin = superclass => class extends superclass {
  cookieSet(...args) { setCookie(...args) }

  cookieGet(...args) { return getCookie(...args) }
}

const setCookie = (name, value, { expiresIn } = {}) => {
  let expires = ''

  if (!isNaN(expiresIn)) {
    expires = new Date()
    expires.setTime(expires.getTime() + expiresIn)
    expires = `; expires=${expires.toUTCString()}`
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`
}

// Read the specified cookie and subject it to the specified decoding
// algorithm.
//
// Currently only one algorithm is supported, `{ decoder: 'rails' }`, which
// will decode a cookies signed by Rails.
//
const getCookie = (name, { decoder } = {}) => {
  const regexp = new RegExp(`\\s*${name}=(.*)`)

  const match = _reduce(
    document.cookie.split(';'),
    (match, cookie) => match || cookie.match(regexp),
    null
  )

  if (!match)
    return null
  else if (decoder === 'rails')
    return decodeRailsCookie(match[1])
  else
    return match[1]
}

const decodeRailsCookie = cookie => {
  const cookieValue = unescape(cookie.split('--')[0])
  const cookiePayload = JSON.parse(atob(cookieValue))
  const decodedStoredValue = atob(cookiePayload._rails.message)
  return JSON.parse(decodedStoredValue)
}

export default Mixin
