var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " new";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <button class=\"border-0 btn btn-link d-inline-block p-0\" disabled>\n      <i class=\"fa-fw fa-star fal opacity-20\"></i>\n    </button>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <form action=\"#\" class=\"create\" method=\"post\">\n      <input name=\"managed_search[collection][id]\" type=\"hidden\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"collection") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\n      <input name=\"managed_search[ctx][ll]\" type=\"hidden\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"ctx") : depth0)) != null ? lookupProperty(stack1,"ll") : stack1), depth0))
    + "\">\n      <input name=\"managed_search[name]\" type=\"hidden\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\">\n      <input name=\"managed_search[original_query]\" type=\"hidden\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"originalQuery") : depth0), depth0))
    + "\">\n      <input name=\"managed_search[query]\" type=\"hidden\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"query") : depth0), depth0))
    + "\">\n      <input name=\"managed_search[type]\" type=\"hidden\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "\">\n\n      <button class=\"border-0 btn btn-link d-inline-block p-0 tippy\" data-tippy-placement=\"bottom\" data-tooltip=\""
    + alias2(__default(require("../../javascript/src/shared/handlebars/t.js")).call(alias3,"js.managed_searches.toggle.create",{"name":"t","hash":{"defaultValue":"Activate tracking"},"data":data,"loc":{"start":{"line":15,"column":113},"end":{"line":15,"column":187}}}))
    + "\" type=\"submit\">\n        <i class=\"fa-fw fa-star fal\"></i>\n      </button>\n    </form>\n\n    <form action=\"#\" class=\"delete\" method=\"delete\">\n      <input name=\"id\" type=\"hidden\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"/>\n\n      <button class=\"border-0 btn btn-link d-inline-block p-0 tippy\" data-tippy-placement=\"bottom\" data-tooltip=\""
    + alias2(__default(require("../../javascript/src/shared/handlebars/t.js")).call(alias3,"js.managed_searches.toggle.delete",{"name":"t","hash":{"defaultValue":"Deactivate tracking"},"data":data,"loc":{"start":{"line":23,"column":113},"end":{"line":23,"column":189}}}))
    + "\" type=\"submit\">\n        <i class=\"fa-fw fa-star fas\"></i>\n      </button>\n    </form>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"d-inline-block managed-search-toggle"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":49},"end":{"line":1,"column":78}}})) != null ? stack1 : "")
    + "\" data-uid=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"_uid") : depth0), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disabled") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":27,"column":9}}})) != null ? stack1 : "")
    + "</span>\n";
},"useData":true});