import tippy, { delegate } from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light-border.css'
import 'tippy.js/themes/translucent.css'

import '@/shared/stylesheets/tippy.scss'

export class Tippy {
  start(container, { selector = '.tooltip' } = {}) {
    tippy.setDefaultProps({ theme: 'translucent' })

    delegate(container, {
      allowHTML: true,
      content: reference => $(reference).data('tooltip'),
      target: selector
    })

    // Suppress scroll when clicking Tippy anchors.
    //
    $(container).on('click', 'a[data-tippy-trigger=click]', () => false)
  }
}
