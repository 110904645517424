import '@/shared/stylesheets/select2/select2.scss'

// The full version of Select2 is required in order to use features like the
// `dropdownCssClass` option.
//
import 'select2/dist/js/select2.full'
import { Base } from '@/shared/components/observers/base'

// `closeOnClear`   If truthy ensure the select remains closed when being
//                  cleared. Default: false.
//
export class Select2 extends Base {
  constructor(...args) {
    super({ id: 'Select2Observer' })

    this._defaults = Object.assign({
      allowClear: true,
      closeOnClear: false,
      placeholder: 'None',
      theme: 'bootstrap-5'
    }, ...args)
  }

  callback(element, args) {
    const $element = $(element)
    const options = $element.data('select2-options') || {}

    $element.select2(Object.assign({}, this._defaults, args, options))

    // If `closeOnClear` is true (default: false) add some callbacks to ensure
    // the select closes and remains closed when it is being cleared.
    //
    if (options.closeOnClear) {
      $element.on('select2:opening', e => {
        if ($element.data('unselecting')) {
          $element.removeData('unselecting')
          e.preventDefault()
        }
      })
        .on('select2:unselecting', () => $element.data('unselecting', true))
    }
  }

  start(container, { selector = 'select:not(.no-select2)', ...args } = {}) {
    super.start(container, { selector: selector, ...args })
  }
}
