import { Search } from '@components/search/search'

// Logic shared by initializeArchive and initialiseNewsFeed.
//
export default (form, searchViewBuilder) => {
  const $form = $(form)

  // Auto-submit whenever any "filters" are changed.
  //
  // FIXME: The `change` event fires multiple time when clearing a multiple
  //        select. It doesn't seem to be a problem, but it's not desirable.
  //
  $form.on('change', '.filter', event => $form.trigger('submit'))

  // NOTE: If we ever want to stop performing full-page loads when changing the
  //       search, we'll need to determine the `query` (used by pagination etc.)
  //       by serialising the form. For now we can get away with reading it once
  //       on page load. Note that we are using the _original_ query, prior to
  //       the extraction of any filters.
  //
  const search = new Search({
    ctx: { ll: $('input.ctx-ll', $form).val() },
    explain: $('input#_explain', $form).val(),
    query: $form.data('original-query')
  })
  const searchView = searchViewBuilder(search)
  searchView.start()

  return searchView
}
