import { NewsFeed as SearchNewsFeedView }
  from '@components/search/views/news_feed'
import initializeSearchForm from '@/app/initializers/searchForm'

export default () => {
  if ($('body.news-feeds.show').length === 0) return

  const $form = $('form#primary-search')
  const searchViewBuilder = search => new SearchNewsFeedView({
    container: '.search-results',
    pager: '.pagination',
    search: search
  })
  initializeSearchForm($form, searchViewBuilder)
}
